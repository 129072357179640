<template>
  <div class="container" v-if="!!orders">
    <div
      class="w-full border border-gray-400 rounded-md px-8 py-2 flex font-bold"
    >
      <div class="flex-grow">
        Lista ordini
      </div>
      <div>{{ orders.length }} orders</div>
    </div>
    <div class="w-full mt-8" v-for="(order, o) in orders" :key="o">
      <div class="border border-gray-500 rounded-md">
        <div
          class="w-full bg-gray-200 text-xs px-4 py-2 grid grid-cols-12 rounded-t-md"
        >
          <div class="col-span-3">
            <h4 class="uppercase">
              ID ORDINE:
            </h4>
            123
          </div>
          <div class="col-span-3">
            <h4 class="uppercase">
              Effettuato il:
            </h4>
            12/13/14
          </div>
          <div class="col-span-3">
            <h4 class="uppercase">
              Stato:
            </h4>

            <i class="mdi mdi-circle text-green-500"></i> Pagato
          </div>
        </div>
        <div class="w-full px-4 py-2 flex">
          <div class="w-48 h-20 bg-gray-300">IMG</div>
          <div class="flex-grow px-4">
            <h1>Product name</h1>
            <p>Product description</p>
          </div>
          <div>
            <small class="uppercase">totale</small>
            <div class="font-bold text-2xl">{{ "99" | price }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API_URL } from "@/app-axios";
import { mapState } from "vuex";
export default {
  data() {
    return {
      orders: []
    };
  },
  async mounted() {
    let options = {
      url: "/orders",
      method: "GET",
      params: {
        seller_id: this.company.company_id
      },
      headers: {
        "aws-access-token": this.$store.getters["auth/token"]
      }
    };
    console.log("Options ", options);
    let orders = await API_URL(options);
    this.orders = orders.data.content;
    console.log("Orders ", orders);
  },
  computed: {
    ...mapState("createstore", ["company"])
  }
};
</script>

<style></style>
